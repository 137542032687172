<template>
  <v-row>
    <v-col cols="12">
      <h2>ระบบจัดการการแสดงหน้า Feature</h2>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title> แนะนำเพื่อนชั้นที่ 1 </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="affiliate1Ft"
                label="เลือกการแสดงผล"
                :items="featureItems"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <!-- <v-col cols="6">
      <v-card>
        <v-card-title> แนะนำเพื่อนชั้นที่ 2 </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="affiliate2Ft"
                label="เลือกการแสดงผล"
                :items="featureItems"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col> -->
    <v-col cols="6">
      <v-card>
        <v-card-title> โบนัสคืนยอดเสีย </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="bonusReloseFt"
                label="เลือกการแสดงผล"
                :items="featureItems"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title> คูปอง </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="couponFt"
                label="เลือกการแสดงผล"
                :items="featureItems"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <!-- <v-col cols="6">
      <v-card>
        <v-card-title> วงล้อ </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="wheelFt"
                label="เลือกการแสดงผล"
                :items="featureItems"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col> -->
    <v-col cols="6"></v-col>
    <v-divider class="mx-3"/>
    <v-col cols="12" class="d-flex align-end">
      <v-spacer />
      <span>
        <v-btn color="warning" class="mx-3"> ย้อนกลับ </v-btn>
        <v-btn color="success" :loading="loading" @click="submitData"> บันทึก </v-btn>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store';
export default {
  components: {
    SelectInput,
  },
  data() {
    return {
      affiliate1Ft: 0,
      affiliate2Ft: 0,
      bonusReloseFt: 0,
      couponFt: 0,
      wheelFt: 0,
      featureItems: [
        {
          text: this.$t('close'),
          value: 0,
        },
        {
          text: this.$t('open'),
          value: 1,
        }
      ],
      featureEnabled: [],
      loading: false,
    }
  },
  async created () {
    // call data init
    // this.addLogPage()
    this.$store.dispatch('getSettingFeature').then(res => {
      if(res){
        this.featureEnabled = res
        res.forEach(el => {
          switch (el?.page) {
            case 'affiliate1':
              this.affiliate1Ft = el?.value
              break;
            case 'affiliate2':
              this.affiliate2Ft = el?.value
              break;
            case 'cashback':
              this.bonusReloseFt = el?.value
              break;
            case 'coupon':
              this.couponFt = el?.value
              break;
            case 'wheel':
              this.wheelFt = el?.value
              break;
            default:
              break;
          }
        });
      }
    })
  },
  methods: {
    async addLogPage(){
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
            data = {
              ip: el.ip,
              name: 'ตั้งค่า Feature',
              url: window.location.href,
              detail: 'ระบบจัดการการแสดงหน้า Feature',
              admin: userSystem.name ? userSystem.name : '',
            }
          )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async submitData(){
      this.loading = true
      const baseFeatures = [{
          page: 'affiliate1',
          value: this.affiliate1Ft
        },{
          page: 'affiliate2',
          value: this.affiliate2Ft
        },{
          page: 'cashback',
          value: this.bonusReloseFt
        },{
          page: 'coupon',
          value: this.couponFt
        },{
          page: 'wheel',
          value: this.wheelFt
        }]

      for (let item of this.featureEnabled) {
        for (let ft of baseFeatures) {
          if (item.page === ft.page) {
            item.value = ft.value
          }
        }
      }
      const data = this.featureEnabled
      try {
        await this.$store.dispatch('postSettingFeature', data)
        this.$swal.fire({
          icon: 'success',
          title: this.$t('update_success'),
        })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: this.$t('update_fail'),
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
